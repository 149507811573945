@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'McKinsey Sans';
  src: url('../public/fonts/McKinseySans-Regular.woff2') format('woff2'),
      url('../public/fonts/McKinseySans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'McKinsey Sans';
  src: url('../public/fonts/McKinseySans-Medium.woff2') format('woff2'),
      url('../public/fonts/McKinseySans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'McKinsey Sans';
  src: url('../public/fonts/McKinseySans-MediumItalic.woff2') format('woff2'),
      url('../public/fonts/McKinseySans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'McKinsey Sans';
  src: url('../public/fonts/McKinseySans-LightItalic.woff2') format('woff2'),
      url('../public/fonts/McKinseySans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'McKinsey Sans';
  src: url('../public/fonts/McKinseySans-Italic.woff2') format('woff2'),
      url('../public/fonts/McKinseySans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'McKinsey Sans';
  src: url('../public/fonts/McKinseySans-Light.woff2') format('woff2'),
      url('../public/fonts/McKinseySans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bower';
  src: url('../public/fonts/Bower-Bold.woff2') format('woff2'),
      url('../public/fonts/Bower-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bower';
  src: url('../public/fonts/Bower-Bold.woff2') format('woff2'),
      url('../public/fonts/Bower-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

#root {
  height: 100dvh;
}

html, body {
  overscroll-behavior: none;
}

body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'McKinsey Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}